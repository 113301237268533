<form #userForm="ngForm" (ngSubmit)="saveUser()">
    <h2 mat-dialog-title>
        {{ mode === Mode.CREATE ? "Create" : "Edit" }} User
    </h2>
    <mat-dialog-content>
        <mat-form-field style="width: 80%">
            <input
                matInput
                [(ngModel)]="model.principalId"
                id="user-id"
                name="user-id"
                placeholder="User-ID"
                maxlength="64"
                type="text"
                required
                [disabled]="mode === Mode.EDIT"
            />
        </mat-form-field>
        <mat-form-field style="width: 100%">
            <input
                matInput
                [(ngModel)]="model.displayName"
                id="display-name"
                name="display-name"
                placeholder="Display-Name"
                maxlength="64"
                type="text"
                required
            />
        </mat-form-field>

        <mat-form-field style="width: 100%">
            <mat-select
                [multiple]="true"
                id="roles-select"
                name="roles-select"
                [(ngModel)]="model.roles"
                [placeholder]="'User Roles'"
            >
                <mat-option *ngFor="let role of roles" value="{{ role.id }}">{{
                    role.description
                }}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-checkbox
            name="enabled-check"
            id="enabled-check"
            [(ngModel)]="model.enabled"
            >Enabled</mat-checkbox
        >
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-stroked-button (click)="openDeleteModal()" [disabled]="mode !== Mode.EDIT" style="color: red" *ngIf="mode == 1">
          Delete
        </button>
        <button mat-stroked-button type="submit" [disabled]="!userForm.valid">
            Save
        </button>
        <button
            type="button"
            mat-stroked-button
            style="margin-left: 10px"
            [mat-dialog-close]="false"
        >
            Cancel
        </button>
    </mat-dialog-actions>
</form>
