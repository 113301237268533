<form style="width: 600px">
    <h2 mat-dialog-title id="title">
        {{
            mode === Mode.CREATE
                ? "Create Vacation Request"
                : mode === Mode.EDIT
                ? "Edit Vacation Request"
                : mode === Mode.CONFIRM
                ? "Release the days for new Vacation Request"
                : "Display Vacation"
        }}
    </h2>
    <mat-dialog-content>
        <mat-form-field style="width: 100%">
            <mat-select
                required
                id="user"
                name="user"
                [(ngModel)]="selectedUser"
                [placeholder]="'User'"
                [disabled]="!(hrRole && this.mode === Mode.CREATE)"
                (selectionChange)="reloadVacations(); reloadLeftVacationDays(); reloadMaxEndDate()"
            >
                <mat-option
                    *ngFor="let user of availableUsers"
                    class="left circle"
                    [value]="user.principalId"
                >
                    {{ user.displayName }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </mat-dialog-content>

    <mat-dialog-content>
        <div fxLayout="row" fxLayoutAlign="space-between">
            <mat-form-field fxFlex="1 1 48%">
                <input
                    matInput
                    id="startDate"
                    name="startDate"
                    placeholder="Start date"
                    [min]="hrRole ? min : today"
                    [max]="
                        mode === Mode.CREATE || hrRole
                            ? endDayDatepicker || max
                            : mode === Mode.EDIT
                            ? vacationModelMinDate
                            : startDayDatepicker
                    "
                    [matDatepicker]="startPicker"
                    (dateChange)="
                        numberVacationDays = 0; reloadLeftVacationDays()
                    "
                    [(ngModel)]="startDayDatepicker"
                    required
                    [disabled]="
                        !this.selectedUser ||
                        mode === Mode.DISPLAY ||
                        mode === Mode.CONFIRM ||
                        (!hrRole && startDayDatepicker?.isBefore(today))
                    "
                />
                <mat-datepicker-toggle
                    matSuffix
                    [for]="startPicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #startPicker></mat-datepicker>
            </mat-form-field>

            <mat-form-field fxFlex="1 1 48%">
                <input
                    matInput
                    id="endDate"
                    name="endDate"
                    placeholder="End date"
                    [min]="
                        mode === Mode.CREATE
                            ? hrRole
                                ? startDayDatepicker || min
                                : startDayDatepicker || today
                            : mode === Mode.EDIT
                            ? vacationModelMaxDate
                            : endDayDatepicker
                    "
                    [max]="max"
                    [matDatepicker]="endPicker"
                    (dateChange)="
                        numberVacationDays = 0; reloadLeftVacationDays()
                    "
                    [(ngModel)]="endDayDatepicker"
                    required
                    [disabled]="
                        !this.selectedUser ||
                        mode === Mode.DISPLAY ||
                        mode === Mode.CONFIRM ||
                        (!hrRole && startDayDatepicker?.isBefore(today))
                    "
                />
                <mat-datepicker-toggle
                    matSuffix
                    [for]="endPicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #endPicker></mat-datepicker>
            </mat-form-field>
        </div>
    </mat-dialog-content>
    <br />
    <mat-dialog-content style="min-height: 600px">
        <app-calendar
            mat-dialog-content
            *ngIf="vacationDays && startDayDatepicker && endDayDatepicker"
            [allowSelection]="
                mode !== Mode.DISPLAY &&
                mode !== Mode.CONFIRM &&
                (hrRole || startDayDatepicker.isSameOrAfter(today))
            "
            [calendarItems]="calendarItems"
            [focusDay]="startDayDatepicker"
            [minViewport]="startDayDatepicker"
            [maxViewport]="endDayDatepicker"
            [minDate]="startDayDatepicker"
            [maxDate]="endDayDatepicker"
            (selection)="handleCalendarItem($event)"
        ></app-calendar>
    </mat-dialog-content>

    <div fxLayout="row" fxLayoutAlign="space-between">
        <div style="padding: 4px 15px">
            <div id="daysSelected" fxFlexAlign="center">
                {{
                    mode === Mode.DISPLAY || mode === Mode.CONFIRM
                        ? "vacation days:"
                        : " days selected:"
                }}
                {{ numberVacationDays }}
            </div>
            <div id="daysLeft" fxFlexAlign="center" *ngIf="mode !== Mode.CONFIRM">
                days left: {{ leftVacationDaysDisplay }}
            </div>
            <div fxFlexAlign="center" *ngIf="leftVacationDaysDisplay < 0">
                ( ! ) Unable to save, not enough days left
            </div>
        </div>
        <mat-dialog-actions>
            <button
                id="delete"
                mat-stroked-button
                color="{{ (mode === Mode.EDIT || mode === Mode.DISPLAY) ? 'warn' : 'primary' }}"
                *ngIf="mode === Mode.EDIT || mode === Mode.DISPLAY || mode === Mode.CONFIRM"
                (click)="deleteVacationRequest()"
            >
                Delete
            </button>
            <button
                id="save"
                mat-stroked-button
                *ngIf="mode === Mode.EDIT || mode === Mode.CREATE"
                [disabled]="
                    numberVacationDays === 0 ||
                    mode === Mode.DISPLAY ||
                    mode === Mode.CONFIRM ||
                    (!hrRole && startDayDatepicker.isBefore(today)) ||
                    leftVacationDaysDisplay < 0
                "
                (click)="saveVacationRequest()"
            >
                Save
            </button>
            <button
                type="button"
                id="exit"
                mat-stroked-button
                style="margin-left: 8px"
                [mat-dialog-close]="false"
            >
                {{ mode === Mode.DISPLAY ? "Close" : "Cancel" }}
            </button>
        </mat-dialog-actions>
    </div>
</form>
