import { Component, OnInit, Inject } from '@angular/core';
import {UserDetails, UserModel} from '../userDetails';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UserService} from '../user.service';

@Component({
  selector: 'app-delete-user-dialog',
  templateUrl: './delete-user-dialog.component.html',
  styleUrls: ['./delete-user-dialog.component.sass']
})
export class DeleteUserDialogComponent implements OnInit {

  public model: UserModel;

  constructor(private userService: UserService, @Inject(MAT_DIALOG_DATA) public user: UserModel, private dialogRef: MatDialogRef<UserModel>) {
     this.model = user;
  }

  ngOnInit(): void {
  }

  hardDeleteUser(){
    this.userService.hardDeleteUser(this.model).subscribe(
          {complete: () => {
          this.dialogRef.close(true);
          location.reload();}}
        );
    }

}
