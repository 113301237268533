<form #userForm="ngForm" (ngSubmit)="saveCustomer()">
    <h2 mat-dialog-title>
        {{ mode === Mode.CREATE ? "Create" : "Edit" }} Customer
    </h2>
    <mat-dialog-content>
        <mat-form-field style="width: 100%">
            <input
                matInput
                [(ngModel)]="model.name"
                id="customer-name"
                name="customer-name"
                placeholder="Customer Name"
                maxlength="64"
                type="text"
                required
            />
        </mat-form-field>
        <mat-form-field style="width: 100%">
            <input
                matInput
                [(ngModel)]="model.shortName"
                id="customer-shortname"
                name="customer-shortname"
                placeholder="Customer Short Name"
                maxlength="64"
                type="text"
                required
            />
        </mat-form-field>
        <div>
            <mat-checkbox
                name="enabled-check"
                id="enabled-check"
                [(ngModel)]="model.enabled"
                >Enabled</mat-checkbox
            >
        </div>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-stroked-button type="submit" [disabled]="!userForm.valid">
            Save
        </button>
        <button
            mat-stroked-button
            style="margin-left: 10px"
            type="button"
            [mat-dialog-close]="false"
        >
            Cancel
        </button>
    </mat-dialog-actions>
</form>
