import { Component, Inject, OnInit } from "@angular/core";
import { UserService } from "../user.service";
import { UserDetails, UserModel } from "../userDetails";
import { Role } from "../role";
import { Mode } from "../../mode";
import { MAT_DIALOG_DATA, MatDialogRef , MatDialog} from "@angular/material/dialog";
import {DeleteUserDialogComponent} from '../delete-user-dialog/delete-user-dialog.component';

@Component({
    selector: "app-create-user-dialog",
    templateUrl: "./create-user-dialog.component.html",
    styleUrls: ["./create-user-dialog.component.sass"],
})
export class CreateUserDialogComponent implements OnInit {
    public roles: Role[];

    public model: UserModel;
    public mode: Mode;

    public Mode = Mode; // For template


  constructor(private userService: UserService, @Inject(MAT_DIALOG_DATA) public user: UserDetails, private dialogRef: MatDialogRef<UserDetails>, private deleteDialog: MatDialog) {
        if (user) {
            this.model = UserModel.fromUser(user);
            this.mode = Mode.EDIT;
        } else {
            this.model = new UserModel();
            this.mode = Mode.CREATE;
        }
    }

    ngOnInit() {
        this.userService.readRoles().subscribe((next) => (this.roles = next));
    }

    saveUser() {
        this.userService.saveUser(this.model);
        this.dialogRef.close(true);
    }


  openDeleteModal(){
    this.deleteDialog.open(DeleteUserDialogComponent,
    {
        data: this.model,
        maxWidth: '450px'
    })
  }

    deleteUser() {
        this.userService
            .deleteUser(this.model)
            .subscribe({ complete: () => this.dialogRef.close(true) });
    }
}
