<form #absenceForm="ngForm" (ngSubmit)="saveUsersAvailableVacationDays()">
    <h2 id="title" mat-dialog-title>
        {{ mode === Mode.CREATE ? "Create" : "Edit" }} users available vacation
        days
    </h2>
    <mat-dialog-content>
        <div>
            <mat-form-field style="width: 100%">
                <mat-select
                    [multiple]="true"
                    required="true"
                    id="user"
                    name="user"
                    [disabled]="mode === Mode.EDIT"
                    [(ngModel)]="selectedUsers"
                    [placeholder]="'Select Users'"
                >
                    <mat-option
                        *ngFor="let user of availableUsers"
                        class="left circle"
                        [value]="user.principalId"
                    >
                        {{ user.displayName }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between">
            <mat-form-field fxFlex="1 1 30%">
                <input
                    [max]="validUntil"
                    matInput
                    id="startDate"
                    name="startDate"
                    [matDatepicker]="startPicker"
                    placeholder="valid from"
                    [(ngModel)]="validFrom"
                    required
                />
                <mat-datepicker-toggle
                    matSuffix
                    [for]="startPicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #startPicker></mat-datepicker>
            </mat-form-field>

            <mat-form-field fxFlex="1 1 30%">
                <input
                    [min]="validFrom"
                    matInput
                    id="endDate"
                    name="endDate"
                    [matDatepicker]="endPicker"
                    placeholder="valid until"
                    [(ngModel)]="validUntil"
                    required
                />
                <mat-datepicker-toggle
                    matSuffix
                    [for]="endPicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #endPicker></mat-datepicker>
            </mat-form-field>

            <div fxFlex="1 1 30%">
                <div fxLayout="row">
                    <button
                        id="minus"
                        mat-button
                        type="button"
                        class="addHalfDayButton"
                        (click)="addHalfDay(-0.5)"
                    >
                        &minus;
                    </button>
                    <mat-form-field style="min-width: 40px">
                        <mat-label>days</mat-label>
                        <input
                            id="days"
                            matInput
                            type="number"
                            [min]="0"
                            [max]="99"
                            [step]="0.5"
                            [(ngModel)]="numberOfVacationDays"
                            name="numberVacationDays"
                            required
                        />
                    </mat-form-field>
                    <button
                        id="plus"
                        mat-button
                        type="button"
                        class="addHalfDayButton"
                        (click)="addHalfDay(0.5)"
                    >
                        +
                    </button>
                </div>
            </div>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions>
        <button
            id="save"
            mat-stroked-button
            type="submit"
            [disabled]="!absenceForm.valid"
        >
            Save
        </button>
        <button
            id="exit"
            type="button"
            mat-stroked-button
            style="margin-left: 10px"
            [mat-dialog-close]="false"
        >
            Cancel
        </button>
    </mat-dialog-actions>
</form>
