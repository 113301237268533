import { Component, Inject, OnInit } from '@angular/core';
import { ProjectRoleDTO } from "../../../project-role";
import { ProjectRoleService } from "../../../project-role.service";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UserService } from "../../../../users/user.service";
import { Project } from "../../../project";

@Component({
  selector: 'app-edit-compensation-dialog',
  templateUrl: './edit-compensation-dialog.component.html',
  styleUrls: ['./edit-compensation-dialog.component.sass']
})
export class EditCompensationDialogComponent implements OnInit {
    public isEditMode: boolean;

    public dialogModel: EditCompensationDialogModel;
    public roles: ProjectRoleDTO[];
    public compensatedRoles: ProjectRoleDTO[];


    constructor(
            @Inject(MAT_DIALOG_DATA) public data: {role: ProjectRoleDTO | null, compensation: number | null, projectId: number | null},
            private userService: UserService,
            private roleService: ProjectRoleService
        ) {
            this.roleService.readAll().subscribe(
                roles => this.roles = roles
            )

            this.roleService.getCompensatedRolesForProject(data.projectId).subscribe(
                compensatedRoles => this.compensatedRoles = compensatedRoles
            )
        }

    ngOnInit(): void {
            if (this.data.role) {
                this.isEditMode = true;
                this.dialogModel = {
                    roleId: this.data.role.id,
                    compensation: this.data.compensation,
                    projectId: this.data.projectId
                };
            } else {
                this.isEditMode = false;
                this.dialogModel = new EditCompensationDialogModel();
            }
        }

    getFilteredRoles(): ProjectRoleDTO[] {
        let filteredRoles = this.roles.filter(role =>
                !this.compensatedRoles.some(compensatedRole => compensatedRole.id === role.id)
            );
        return filteredRoles;
    }

}

export class EditCompensationDialogModel {
    roleId: number;
    compensation: number;
    projectId: number;
}
