<form #absenceForm="ngForm" (ngSubmit)="saveAbsence()">
    <h2 id="title" mat-dialog-title>
        {{
            mode === Mode.CREATE
                ? "Create"
                : mode === Mode.DISPLAY
                ? "Display"
                : "Edit"
        }}
        Absence
    </h2>
    <mat-dialog-content>
        <div>
            <mat-form-field style="width: 100%">
                <mat-select
                    required
                    id="user"
                    name="user"
                    [(ngModel)]="model.user.principalId"
                    [placeholder]="'User'"
                    [disabled]="mode === Mode.DISPLAY || !hrRole"
                >
                    <mat-option
                        *ngFor="let user of availableUsers"
                        class="left circle"
                        [value]="user.principalId"
                    >
                        {{ user.displayName }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field style="width: 100%">
                <mat-select
                    required
                    id="type"
                    name="type"
                    [(ngModel)]="model.type"
                    [placeholder]="'Absence Type'"
                    [disabled]="mode === Mode.DISPLAY"
                >
                    <mat-option
                        class="left circle"
                        [value]="AbsenceType.BUSINESSTRIP"
                        >{{ AbsenceType.BUSINESSTRIP }}</mat-option
                    >
                    <mat-option
                        class="left circle"
                        [value]="AbsenceType.ILLNESS"
                        >{{ AbsenceType.ILLNESS }}</mat-option
                    >
                </mat-select>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between">
            <mat-form-field fxFlex="1 1 48%">
                <input
                    [max]="model.end"
                    matInput
                    id="startDate"
                    name="startDate"
                    [matDatepicker]="startPicker"
                    placeholder="Start date"
                    [(ngModel)]="model.start"
                    (dateChange)="checkAndChangeDaySegments()"
                    [disabled]="mode === Mode.DISPLAY"
                    required
                />
                <mat-datepicker-toggle
                    matSuffix
                    [for]="startPicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #startPicker></mat-datepicker>
            </mat-form-field>

            <mat-form-field fxFlex="1 1 48%">
                <input
                    [min]="model.start"
                    matInput
                    id="endDate"
                    name="endDate"
                    [matDatepicker]="endPicker"
                    placeholder="End date"
                    [(ngModel)]="model.end"
                    (dateChange)="checkAndChangeDaySegments()"
                    [disabled]="mode === Mode.DISPLAY"
                    required
                />
                <mat-datepicker-toggle
                    matSuffix
                    [for]="endPicker"
                ></mat-datepicker-toggle>
                <mat-datepicker #endPicker></mat-datepicker>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="space-between">
            <div fxFlex="1 1 48%">
                <mat-radio-group
                    class="day-segment-radio-group"
                    [(ngModel)]="model.startDateDaySegment"
                    [disabled]="
                        !model.start || !model.end || mode === Mode.DISPLAY
                    "
                    id="startAbsenceDaySegments"
                    name="startAbsenceDaySegments"
                    required
                >
                    <mat-radio-button
                        id="start-day-segment-whole-day"
                        class="day-segment-radio-button"
                        [value]="AbsenceDaySegment.WHOLE_DAY"
                    >
                        {{ AbsenceDaySegment.WHOLE_DAY }}
                    </mat-radio-button>

                    <mat-radio-button
                        id="start-day-segment-first-half"
                        class="day-segment-radio-button"
                        [value]="AbsenceDaySegment.FIRST_HALF"
                        [disabled]="
                            !model.start || !model.end || !TimeRangeUtil.isSameDay(model.start, model.end)
                        "
                    >
                        {{ AbsenceDaySegment.FIRST_HALF }}
                    </mat-radio-button>

                    <mat-radio-button
                        id="start-day-segment-second-half"
                        class="day-segment-radio-button"
                        [value]="AbsenceDaySegment.SECOND_HALF"
                    >
                        {{ AbsenceDaySegment.SECOND_HALF }}
                    </mat-radio-button>
                </mat-radio-group>
            </div>

            <div fxFlex="1 1 48%">
                <mat-radio-group
                    class="day-segment-radio-group"
                    [(ngModel)]="model.endDateDaySegment"
                    id="endAbsenceDaySegments"
                    name="endAbsenceDaySegments"
                    [disabled]="
                        !model.start ||
                        !model.end ||
                        TimeRangeUtil.isSameDay(model.start, model.end) ||
                        mode === Mode.DISPLAY
                    "
                    required
                >
                    <mat-radio-button
                        id="end-day-segment-whole-day"
                        class="day-segment-radio-button"
                        [value]="AbsenceDaySegment.WHOLE_DAY"
                    >
                        {{ AbsenceDaySegment.WHOLE_DAY }}
                    </mat-radio-button>

                    <mat-radio-button
                        id="end-day-segment-first-half"
                        class="day-segment-radio-button"
                        [value]="AbsenceDaySegment.FIRST_HALF"
                    >
                        {{ AbsenceDaySegment.FIRST_HALF }}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions>
        <button
            id="delete"
            mat-stroked-button
            color="warn"
            *ngIf="mode === Mode.EDIT"
            [disabled]="!absenceForm.valid"
            (click)="deleteAbsence()"
        >
            Delete
        </button>
        <button
            id="save"
            mat-stroked-button
            type="submit"
            *ngIf="mode === Mode.EDIT || mode === Mode.CREATE"
            [disabled]="!absenceForm.valid"
        >
            Save
        </button>
        <button
            id="exit"
            type="button"
            mat-stroked-button
            style="margin-left: 10px"
            [mat-dialog-close]="false"
        >
            {{ mode === Mode.DISPLAY ? "Close" : "Cancel" }}
        </button>
    </mat-dialog-actions>
</form>
