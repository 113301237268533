import { Component, OnInit } from "@angular/core";
import { UserDetails } from "../userDetails";
import { UserService } from "../user.service";
import { MatDialog } from "@angular/material/dialog";
import { CreateUserDialogComponent } from "../create-user-dialog/create-user-dialog.component";
import { Observable } from "rxjs";

@Component({
    selector: "app-user-overview",
    templateUrl: "./user-overview.component.html",
    styleUrls: ["./user-overview.component.sass"],
})
export class UserOverviewComponent implements OnInit {
    public allUsers: Observable<UserDetails[]>;

    constructor(private userService: UserService, private dialog: MatDialog) {}

    ngOnInit() {
        this.reloadAllUsers();
    }

    openUserModal(user: UserDetails) {
        this.dialog
            .open(CreateUserDialogComponent, {
                data: user,
                maxWidth: "450px",
            })
            .afterClosed()
            .subscribe((result) => {
                if (result) {
                    this.reloadAllUsers();
                }
            });
    }

    private reloadAllUsers() {
        this.allUsers = this.userService.readUsers();
    }
}
