import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReportingOverviewComponent } from "./reporting-overview/reporting-overview.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ReportingService } from "./reporting.service";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatButtonModule } from "@angular/material/button";
import { MatTableModule } from "@angular/material/table";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatIconModule } from "@angular/material/icon";
import { MatDialogModule } from "@angular/material/dialog";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatCardModule } from "@angular/material/card";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTabsModule } from "@angular/material/tabs";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        CommonModule,
        MatInputModule,
        MatButtonModule,
        MatInputModule,
        MatSelectModule,
        MatTableModule,
        MatTooltipModule,
        MatIconModule,
        MatDialogModule,
        FlexLayoutModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatDividerModule,
        MatExpansionModule,
        MatCardModule,
        MatStepperModule,
        ReactiveFormsModule,
        MatTabsModule,
    ],
    declarations: [ReportingOverviewComponent],
    providers: [ReportingService],
})
export class ReportingModule {}
