import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AbsenceOverviewComponent } from "./absence-overview/absence-overview.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule } from "@angular/forms";
import { AbsenceService } from "./absence.service";
import { HrGuard } from "../role-guard";
import { CreateAbsenceDialogComponent } from "./create-absence-dialog/create-absence-dialog.component";
import { ConfirmDialogComponent } from "../common/confirm-dialog/confirm-dialog.component";
import { AbsenceSelfServiceOverviewComponent } from "./absence-self-service-overview/absence-self-service-overview.component";
import { CreateVacationRequestDialogComponent } from "./create-vacation-request-dialog/create-vacation-request-dialog.component";
import { VacationService } from "./vacation.service";
import { SchedulerComponent } from "../scheduler/scheduler.component";
import { AbsenceAdminOverviewComponent } from "./absence-admin-overview/absence-admin-overview.component";
import { VerifyVacationDialogComponent } from "./verify-vacation-dialog/verify-vacation-dialog.component";
import { CalendarComponent } from "../calendar/calendar.component";
import { AvailableVacationDayAdminOverviewComponent } from "./available-vacation-day-admin-overview/available-vacation-day-admin-overview.component";
import { CreateAvailableVacationDaysDialogComponent } from "./create-available-vacation-days-dialog/create-available-vacation-days-dialog.component";
import { MatButtonModule } from "@angular/material/button";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatCardModule } from "@angular/material/card";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatDialogModule } from "@angular/material/dialog";
import { MatRadioModule } from "@angular/material/radio";
import { MatTabsModule } from "@angular/material/tabs";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatRippleModule } from "@angular/material/core";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MatButtonModule,
        FlexLayoutModule,
        MatExpansionModule,
        MatInputModule,
        MatFormFieldModule,
        MatSelectModule,
        MatCardModule,
        MatDatepickerModule,
        MatIconModule,
        MatTooltipModule,
        MatDialogModule,
        MatRadioModule,
        MatTabsModule,
        MatCheckboxModule,
        MatSlideToggleModule,
        MatRippleModule,
    ],
    declarations: [
        AbsenceOverviewComponent,
        CreateAbsenceDialogComponent,
        AbsenceSelfServiceOverviewComponent,
        CreateVacationRequestDialogComponent,
        SchedulerComponent,
        AbsenceAdminOverviewComponent,
        VerifyVacationDialogComponent,
        CalendarComponent,
        AvailableVacationDayAdminOverviewComponent,
        CreateAvailableVacationDaysDialogComponent,
    ],
    providers: [AbsenceService, VacationService, HrGuard],
    entryComponents: [
        CreateAbsenceDialogComponent,
        ConfirmDialogComponent,
        CreateVacationRequestDialogComponent,
        CreateAvailableVacationDaysDialogComponent,
    ],
})
export class AbsenceModule {}
