import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
} from "@angular/core";
import * as moment from "moment";
import { Moment } from "moment";

@Component({
    selector: "app-calendar",
    templateUrl: "./calendar.component.html",
    styleUrls: ["./calendar.component.sass"],
})
export class CalendarComponent implements OnChanges {
    constructor() {}

    /**
     * Definiert, welcher Tag im Kalender initial sichtbar ist.
     */
    @Input()
    focusDay: Moment = moment();

    // Dies ist der Bereich, in dem man selektieren darf
    @Input()
    minDate: Moment = moment().startOf("year");
    @Input()
    maxDate: Moment = moment().endOf("year");

    @Input()
    minViewport: Moment = moment().startOf("year").startOf("isoWeek");

    @Input()
    maxViewport: Moment = moment().endOf("year").endOf("isoWeek");

    @Input()
    allowSelection = true;

    firstDayOfCalendarPage: Moment;

    selectedCalendarItems: CalendarItem[];

    @Output()
    selection: EventEmitter<CalendarItem> = new EventEmitter();

    @Input()
    calendarItems: CalendarItem[] = [];

    calendarDays: Moment[];

    private static getSegmentForSelection(calendarItem: CalendarItem): string {
        if (calendarItem === undefined) {
            return "WHOLE_DAY";
        }

        return calendarItem.daySegment === "WHOLE_DAY"
            ? "FIRST_HALF"
            : calendarItem.daySegment === "FIRST_HALF"
            ? "SECOND_HALF"
            : "WHOLE_DAY";
    }

    ngOnChanges(): void {
        this.firstDayOfCalendarPage = this.focusDay
            .clone()
            .startOf("month")
            .startOf("isoWeek");
        this.calendarDays = this.getCalendarDays();
        if (this.calendarItems) {
            this.selectedCalendarItems = this.calendarItems.map(
                (calendarItem) => ({
                    moment: calendarItem.moment,
                    daySegment: calendarItem.daySegment,
                    clickable: calendarItem.clickable,
                })
            );
        } else {
            this.selectedCalendarItems = [];
        }
        this.selection.emit(null);
    }

    private getCalendarDays(): Moment[] {
        const calendarDays = [];
        for (let i = 0; i < 42; i++) {
            calendarDays.push(
                this.firstDayOfCalendarPage.clone().add(i, "days")
            );
        }

        return calendarDays;
    }

    moveCalendarViewport(amount: number): void {
        this.firstDayOfCalendarPage = this.firstDayOfCalendarPage.add(
            amount,
            "weeks"
        );
        this.calendarDays = this.getCalendarDays();
    }

    scrollViewport($event: WheelEvent): void {
        if ($event.deltaY < 0) {
            if (this.firstDayOfCalendarPage.isAfter(this.minViewport)) {
                this.moveCalendarViewport(-1);
            }
        } else {
            if (
                this.firstDayOfCalendarPage
                    .clone()
                    .add(6, "weeks")
                    .isBefore(this.maxViewport)
            ) {
                this.moveCalendarViewport(1);
            }
        }
    }

    dayEntered(mom: Moment, $event: MouseEvent): void {
        const selectedCalendarItem = this.findSelectedCalendarItems(mom);
        if ($event.buttons === 1) {
            if (selectedCalendarItem) {
                selectedCalendarItem.daySegment =
                    CalendarComponent.getSegmentForSelection(
                        selectedCalendarItem
                    );
            } else {
                this.selectedCalendarItems.push({
                    moment: mom,
                    daySegment: "WHOLE_DAY",
                    clickable: true,
                });
            }
            this.selection.emit({ moment: mom, daySegment: "WHOLE_DAY" });
        } else if ($event.buttons === 2 && selectedCalendarItem) {
            this.selectedCalendarItems = this.selectedCalendarItems.filter(
                (value) => value !== selectedCalendarItem
            );
            this.selection.emit({ moment: mom });
        }
    }

    dayClicked(mom: Moment, $event: MouseEvent): boolean {
        const selectedCalendarItem = this.findSelectedCalendarItems(mom);

        if ($event.buttons === 1) {
            // left click
            if (selectedCalendarItem) {
                selectedCalendarItem.daySegment =
                    CalendarComponent.getSegmentForSelection(
                        selectedCalendarItem
                    );
                this.selection.emit({
                    moment: selectedCalendarItem.moment,
                    daySegment: selectedCalendarItem.daySegment,
                });
            } else {
                const calendarItem = {
                    moment: mom,
                    daySegment: "WHOLE_DAY",
                    clickable: true,
                };
                this.selectedCalendarItems.push(calendarItem);
                this.selection.emit(calendarItem);
            }
        } else if ($event.buttons === 2 && selectedCalendarItem !== undefined) {
            // right click
            this.selectedCalendarItems = this.selectedCalendarItems.filter(
                (value) => value !== selectedCalendarItem
            );
            this.selection.emit({ moment: mom });
        }

        return false;
    }

    findSelectedCalendarItems(mom: Moment): CalendarItem {
        return this.selectedCalendarItems.find((value) =>
            value.moment.isSame(mom.startOf("day"))
        );
    }

    isClickable(mom: Moment): boolean {
        if (
            this.allowSelection &&
            mom.isSameOrAfter(this.minDate) &&
            mom.isSameOrBefore(this.maxDate)
        ) {
            const calendarItem = this.findSelectedCalendarItems(mom);
            return calendarItem === undefined
                ? this.allowSelection
                : calendarItem.clickable;
        } else {
            return false;
        }
    }

    preventDefaults($event: MouseEvent): void {
        $event.preventDefault();
    }
}

export interface CalendarItem {
    moment: Moment;
    daySegment?: string; // FIRST_HALF, SECOND_HALF, WHOLE_DAY
    clickable?: boolean;
}
