<form #roleForm="ngForm">
    <h2 mat-dialog-title>
        {{ isEditMode ? "Edit" : "Create" }} Project Member
    </h2>
    <mat-dialog-content>
        <div fxLayout="row" fxLayoutAlign="space-between">
            <mat-form-field fxFlex="1 1 100%">
                <mat-select
                    required
                    id="user"
                    name="user"
                    [disabled]="isEditMode"
                    [(ngModel)]="dialogModel.principalId"
                    placeholder="User"
                >
                    <mat-option
                        *ngFor="let user of users | async"
                        [value]="user.principalId"
                    >
                        {{ user.displayName }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <mat-form-field style="width: 100%">
            <mat-select
                id="roles"
                name="roles"
                [(ngModel)]="dialogModel.roles"
                multiple
                required
                placeholder="Roles"
            >
                <mat-option *ngFor="let role of roles | async" [value]="role.id"
                    >{{ role.name }} ({{ role.description }})
                </mat-option>
            </mat-select>
        </mat-form-field>
    </mat-dialog-content>

    <mat-dialog-actions>
        <button type="button" mat-stroked-button [mat-dialog-close]="false">
            Cancel
        </button>
        <button
            mat-stroked-button
            style="margin-left: 10px"
            type="submit"
            [disabled]="!roleForm.valid"
            [mat-dialog-close]="dialogModel"
        >
            Save
        </button>
    </mat-dialog-actions>
</form>
