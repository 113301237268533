import { Component } from "@angular/core";
import {
    CustomerAdminGuard,
    HrGuard,
    ProjectAdminGuard,
    ReportingGuard,
    UserAdminGuard,
} from "./role-guard";
import { UserService } from "./users/user.service";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { CreateVacationRequestDialogComponent } from "./absence/create-vacation-request-dialog/create-vacation-request-dialog.component";
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";
import { EventMessage, EventType } from "@azure/msal-browser";
import { CreateAbsenceDialogComponent } from "./absence/create-absence-dialog/create-absence-dialog.component";
import { Mode } from "./mode";
import { BackendService } from "./backend.service";
import { UserModel } from "./users/userDetails";
import { filter } from "rxjs/operators";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
})
export class AppComponent {
    user: UserModel;
    userInitials: string;
    userImgAsBase64: string;

    constructor(
        public msalService: MsalService,
        public userService: UserService,
        public backendService: BackendService,
        public projectAdminGuard: ProjectAdminGuard,
        public customerAdminGuard: CustomerAdminGuard,
        public reportingGuard: ReportingGuard,
        public userAdminGuard: UserAdminGuard,
        public hrGuard: HrGuard,
        public routerService: Router,
        private dialog: MatDialog,
        public msalBroadcastService: MsalBroadcastService
    ) {
        msalBroadcastService.msalSubject$
            .pipe(
                filter(
                    (msg: EventMessage) =>
                        msg.eventType === EventType.LOGIN_SUCCESS
                )
            )
            .subscribe(async () => {
                console.log("first login: init user");
                await this.initUser(); // first login only
            });

        if (msalService.instance.getAllAccounts().length > 0) {
            console.log("website refresh: init user");
            this.initUser(); // after website refresh
        }
    }

    async initUser() {
        console.log("init user called");
        this.userService.readMe().subscribe((user) => {
            console.log("init user callback received");
            this.user = user;
            this.userInitials = this.extractInitials(user.displayName);
        });

        const img = await this.backendService.getUserPhoto();
        this.userImgAsBase64 = await this.blobToBase64(img);
    }

    blobToBase64(blob: Blob): Promise<string> {
        return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result as string);
            reader.readAsDataURL(blob);
        });
    }

    private extractInitials(name: string): string {
        let initials = "";
        for (const part of name.split(" ")) {
            initials += part.charAt(0);
        }
        return initials;
    }

    public login() {
        this.msalService.loginRedirect();
    }

    public logout() {
        this.userService.clearMe();
        this.msalService.logoutRedirect();
        this.user = null;
        this.userInitials = null;
        this.routerService.navigateByUrl("/");
    }

    openCreateVacationRequestModal() {
        this.dialog
            .open(CreateVacationRequestDialogComponent, {
                data: { mode: Mode.CREATE },
                disableClose: true,
            })
            .afterClosed()
            .subscribe(() => {
                const url = this.routerService.url;
                if (
                    url === "/absence/self-service" ||
                    url === "/manage/absences"
                ) {
                    this.routerService.navigateByUrl("/").then(() => {
                        this.routerService.navigated = false;
                        this.routerService.navigate([url]);
                    });
                }
            });
    }

    openCreateAbsenceModal() {
        this.dialog
            .open(CreateAbsenceDialogComponent, {
                data: null,
                disableClose: true,
            })
            .afterClosed()
            .subscribe(() => {
                const url = this.routerService.url;
                if (
                    url === "/manage/absences" ||
                    url === "/absence/self-service"
                ) {
                    this.routerService.navigateByUrl("/").then(() => {
                        this.routerService.navigated = false;
                        this.routerService.navigate([url]);
                    });
                }
            });
    }
}
