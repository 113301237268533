import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CreateUserDialogComponent } from "./create-user-dialog/create-user-dialog.component";
import { FormsModule } from "@angular/forms";
import { UserService } from "./user.service";
import { UserOverviewComponent } from "./user-overview/user-overview.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { MatTableModule } from "@angular/material/table";
import { MatSelectModule } from "@angular/material/select";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatIconModule } from "@angular/material/icon";
import { MatDialogModule } from "@angular/material/dialog";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatRippleModule } from "@angular/material/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { DeleteUserDialogComponent } from './delete-user-dialog/delete-user-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        CommonModule,
        MatInputModule,
        MatButtonModule,
        MatInputModule,
        MatSelectModule,
        MatTableModule,
        MatTooltipModule,
        MatIconModule,
        MatDialogModule,
        FlexLayoutModule,
        MatCheckboxModule,
        MatRippleModule,
        MatFormFieldModule,
    ],
    declarations: [CreateUserDialogComponent, UserOverviewComponent, DeleteUserDialogComponent],
    exports: [],
    providers: [UserService],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UsersModule {}
