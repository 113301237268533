export interface UserInfo {
    principalId: string;
    displayName: string;
}

export interface UserDetails extends UserInfo {
    roles: string[];
    enabled: boolean;
}

export class UserModel implements UserDetails {
    principalId: string;
    displayName: string;
    roles: string[];
    enabled: boolean;

    static fromUser(user: UserDetails): UserModel {
        return {
            principalId: user.principalId,
            displayName: user.displayName,
            roles: user.roles,
            enabled: user.enabled,
        };
    }
}
