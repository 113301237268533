<div fxLayout="row" fxLayoutAlign="space-between start">
    <h1>Home</h1>

    <mat-form-field *ngIf=" me | async as m">
        <mat-select
            multiple
            id="load"
            [(ngModel)]="visibility"
        >
            <mat-option class="left circle" [value]="HomeCard.FAST_ENTRIES">
                Fast entries
            </mat-option>
            <mat-option class="left circle" [value]="HomeCard.USER">
                User Overview
            </mat-option>
            <mat-option class="left circle" [value]="HomeCard.PROJECTS">
                Project Overview
            </mat-option>
            <mat-option class="left circle" [value]="HomeCard.REVENUE" *ngIf="m.roles.includes('PROJECT_ADMIN')">
                Revenue Overview
            </mat-option>
            <mat-option class="left circle" [value]="HomeCard.VACATION">
                Vacation Overview
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>

<div fxLayout="column" *ngIf=" me | async as m">
    <mat-card appearance="outlined" style="width: 100%; margin-bottom: 10px" *ngIf="visibility.includes(HomeCard.FAST_ENTRIES)">

        <h2>Fast entries</h2>
        <mat-vertical-stepper [linear]="false" #stepper *ngIf="myProjects | async as projects" (selectionChange)="onStepChange(m, projects, $event)">
            <mat-step>
                <ng-template matStepLabel>Timesheet</ng-template>

        <div fxLayout="column" class="cs-table" *ngIf="favProject">
            <div class="cs-table-row enclosing" fxLayout="row">
                <div fxFlex="20">Date</div>
                <div fxFlex="20">Project</div>
                <div fxFlex="20">Role</div>
                <div fxFlex="20">Task</div>
                <div fxFlex="20">Hours : Minutes</div>
            </div>
            <div class="cs-table-row" fxLayout="row">
                <mat-form-field fxFlex="20" style="padding-right: 10px">
                    <mat-select
                        [(ngModel)]="currentDate"
                        id="date"
                        name="date"
                        [disabled]="true">
                        <mat-option [value]="currentDate">{{currentDate}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field fxFlex="20" style="padding-right: 10px">
                    <mat-select
                        [(ngModel)]="favProject"
                        id="project"
                        name="project"
                        [disabled]="true">
                        <mat-option [value]="favProject">{{favProject.name}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field fxFlex="20" style="padding-right: 10px">
                    <mat-select
                        required
                        [(ngModel)]="mostUsedRole"
                        id="role"
                        name="role"
                        [disabled]="mostUsedRole"
                        [placeholder]="'Select Role'">
                        <mat-option *ngIf="mostUsedRole" [value]="mostUsedRole">{{mostUsedRole.name}}</mat-option>
                        <mat-option *ngFor="let role of allRoles | async" [value]="role">{{role.name}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field fxFlex="20" style="padding-right: 10px">
                <textarea
                    matInput
                    id="task"
                    name="task"
                    required
                    placeholder="Task(s)"
                    maxlength="255"
                    rows="1"
                    [(ngModel)]="task"
                ></textarea>
                </mat-form-field>

                <mat-form-field style="width: 15%; padding-right: 10px" fxFlex="10">
                    <mat-select
                        id="hours"
                        name="hours"
                        [(ngModel)]="hours"
                        placeholder="Hours"
                    >
                        <mat-option *ngFor="let item of [].constructor(24); let i = index" [value]="i">{{i}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field style="width: 15%; padding-right: 10px" fxFlex="10">
                    <mat-select
                        id="minutes"
                        name="minutes"
                        [(ngModel)]="minutes"
                        placeholder="Hours"
                    >
                        <mat-option *ngFor="let item of [].constructor(4); let i = index" [value]="i * 15">{{i * 15}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxLayout="row" fxLayoutAlign="end">
                <div class="checkmark-container" style="margin: 5px 20px 0px 0px">
                    <mat-icon *ngIf="showCheckmark" class="green-class checkmark-icon" style="margin-right: 10px">done_outline</mat-icon>
                </div>
                <button mat-stroked-button color="primary" fxFlex="15" (click)="saveEntry()" [disabled]="showCheckmark">Add entry</button>
            </div>
        </div>

        <div *ngIf="!favProject">
            Select a running project as default
        </div>

            </mat-step>

            <mat-step>
                <ng-template matStepLabel>Reporting</ng-template>

        <div fxLayout="column" class="cs-table" *ngIf="dataReady">
            <div class="cs-table-row enclosing" fxLayout="row">
                <div fxFlex="25">Time period</div>
                <div fxFlex="25">Project</div>
                <div fxFlex="25">User</div>
                <div fxFlex="25">Language</div>
            </div>
        </div>

        <div class="cs-table-row" fxLayout="row">
            <mat-form-field fxFlex="25" style="padding-right: 10px">
                <mat-select
                    required
                    [(ngModel)]="reportingPeriod"
                    id="reportingPeriod"
                    name="reportingPeriod"
                    [disabled]="false"
                    [placeholder]="'Select Time Period'">
                    <mat-option *ngFor="let p of reportingPeriods" [value]="p.value">{{p.name}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field fxFlex="25" style="padding-right: 10px">
                <mat-select
                    required
                    [(ngModel)]="reportingProject"
                    id="reportingProject"
                    name="reportingProject"
                    [disabled]="false"
                    [placeholder]="'Select Project'">
                    <mat-option *ngFor="let p of myProjects | async" [value]="p">{{p.name}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field fxFlex="25" style="padding-right: 10px" *ngIf="user">
                <mat-select
                    required
                    [(ngModel)]="user"
                    id="user"
                    name="user"
                    [disabled]="!m.roles.includes('REPORTING')"
                    [placeholder]="'Select User'">
                    <div *ngFor="let u of users | async">
                        <mat-option [value]="u">{{u.displayName}}</mat-option>
                    </div>
                    <div *ngIf="!m.roles.includes('REPORTING')">
                        <mat-option *ngIf="reporter | async as r" [value]="r">{{r.displayName}}</mat-option>
                    </div>
                </mat-select>
            </mat-form-field>


            <mat-form-field fxFlex="25" style="padding-right: 10px">
                <mat-select
                    required
                    [(ngModel)]="language"
                    id="language"
                    name="language"
                    [disabled]="false"
                    [placeholder]="'Select Language'">
                    <mat-option *ngFor="let l of languages" [value]="l.value">{{l.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="end">
            <div class="checkmark-container" style="margin: 5px 20px 0px 0px">
                <mat-icon *ngIf="showCheckmark2" class="green-class checkmark-icon" style="margin-right: 10px">done_outline</mat-icon>
            </div>
            <button mat-stroked-button color="primary" fxFlex="15" (click)="downloadReport()" [disabled]="reportingPeriod == null || reportingProject == null || user == null || language == null">Download</button>
        </div>
            </mat-step>

            <mat-step>
                <ng-template matStepLabel>Vacation & Absence</ng-template>
                <div fxLayout="column" fxLayoutAlign="space-between" style="height: 300px" style="text-align: start">

                    <div fxLayout="column" fxLayoutAlign="space-evenly" *ngIf="fastAbsenceOb | async as ab">
                        <h3>Current Absence</h3>
                        <div>Your current Absence: {{ab.type}}</div>
                        <div>Ends at: {{ab.endDate}} ({{ab.endDateDaySegment}})</div>

                        <div fxLayout="row" fxLayoutAlign="space-between" style="margin-top: 20px">
                            <button mat-stroked-button color="primary" (click)="extendAbsence(m, 0.5)" fxFlex="45">Add halfday</button>
                            <button mat-stroked-button color="primary" (click)="extendAbsence(m, 1)" fxFlex="45">Add whole day</button>
                        </div>
                    </div>

                    <h3>Create new Absence</h3>
                    <div fxLayout="row" fxLayoutAlign="space-between">
                        <button mat-stroked-button color="primary" (click)="openVacationRequestDialog()" fxFlex="45">Create Vacation Request</button>
                        <button mat-stroked-button color="primary" (click)="openAbsenceDialog(m)" fxFlex="45">Create Absence</button>
                    </div>

                </div>
            </mat-step>


        </mat-vertical-stepper>
    </mat-card>



<mat-card appearance="outlined" *ngIf="visibility.includes(HomeCard.USER)" style="width: 100%; margin-bottom: 10px">

    <div fxLayout="row" fxLayoutAlign="space-between start">
        <h2>User Overview</h2>
        <mat-form-field>
            <mat-select
                [(ngModel)]="userTimespan"
                id="userDataByYear"
                name="userDataByYear"
                (selectionChange)="reloadUsers()"
            >
                <mat-option class="left circle" [value]="Timespan.YEAR">
                    This year
                </mat-option>
                <mat-option class="left circle" [value]="Timespan.MONTH">
                    This month
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div fxLayout="row">
        <canvasjs-chart *ngIf="userWorkData | async as userData0" [options]="buildSingleChart(userData0, userTitleWorkingHours, 'bar', 'h')" class="chart-style" fxFlex="75"></canvasjs-chart>

        <div fxLayout="column" class="cs-table" fxFlex="25" style="margin-top: 25px; background-color: #595959">

            <div class="cs-table-row enclosing">
                <div fxFlex="75">Name</div>
                <div fxFlex="25">Overtime</div>
            </div>

            <div *ngIf="userWorkData | async as userData0" style="overflow-y: auto; max-height: 300px">
                <div *ngFor="let data of userData0.data; let i = index" class="cs-table-row">
                    <a fxFlex="75">{{userData0.label[i] + ':'}}</a>
                    <a fxFlex="25" [ngClass]="getOvertime(data) > 0 ? 'green-class' : 'red-class'">
                    &nbsp;
                        <a *ngIf="getOvertime(data) > 0">+</a>
                    {{getOvertime(data) + 'h'}}
                    </a>
                </div>
            </div>

        </div>

    </div>

    <div fxLayout="row">
        <canvasjs-chart *ngIf="userHoursPerProjectData | async as userData1" [options]="buildSingleChart(userData1, userTitleHoursPerProject, 'doughnut', 'h')" class="chart-style" fxFlex></canvasjs-chart>
        <canvasjs-chart *ngIf="userProjectMemberData | async as userData2" [options]="buildSingleChart(userData2, 'Projects per Member', 'doughnut')" class="chart-style" fxFlex></canvasjs-chart>
    </div>
    <button mat-stroked-button color="primary" (click)="openUsers()" *ngIf="m.roles.includes('USER_ADMIN')">View Users</button>
</mat-card>


<mat-card appearance="outlined" *ngIf="visibility.includes(HomeCard.PROJECTS)" style="width: 100%; margin-bottom: 10px">
    <div fxLayout="row" fxLayoutAlign="space-between start">
        <h2>Project Overview</h2>

        <mat-form-field>
            <mat-select
                [(ngModel)]="projectTimespan"
                id="projectDataByYear"
                name="projectDataByYear"
                (selectionChange)="reloadProjects()"
            >
                <mat-option class="left circle" [value]="Timespan.YEAR">
                    Next 5 years
                </mat-option>
                <mat-option class="left circle" [value]="Timespan.MONTH">
                    This year
                </mat-option>
            </mat-select>
        </mat-form-field>


    </div>
    <canvasjs-chart *ngIf="projectOverTimeData | async as projectData" [options]="buildSingleChart(projectData, projectTitle, 'line')" class="chart-style" fxFlex></canvasjs-chart>
    <div fxLayout="row">
        <canvasjs-chart *ngIf="projectCustomerData | async as projectData" [options]="buildSingleChart(projectData, 'Projects per Customer', 'doughnut')" class="chart-style" fxFlex></canvasjs-chart>
        <canvasjs-chart *ngIf="projectDistributionData | async as projectData" [options]="buildSingleChart(projectData, 'Project distribution', 'doughnut')" class="chart-style" fxFlex></canvasjs-chart>
    </div>
    <div fxLayout="row">
        <button mat-stroked-button color="primary" (click)="openCustomers()" *ngIf="m.roles.includes('PROJECT_ADMIN')" fxFlex>View Customers</button>
        <button mat-stroked-button color="primary" (click)="openProjects()" *ngIf="m.roles.includes('PROJECT_ADMIN')" fxFlex>View Projects</button>
    </div>
</mat-card>

<!--Only visible if user is Project Admin-->
<mat-card appearance="outlined" *ngIf="visibility.includes(HomeCard.REVENUE) && m.roles.includes('PROJECT_ADMIN')" style="margin-bottom: 10px">

    <div fxLayout="row" fxLayoutAlign="space-between start">
        <h2>Revenue Overview</h2>

        <mat-form-field>
            <mat-select
                [(ngModel)]="revenueTimespan"
                id="revenueTimespan"
                name="revenueTimespan"
                (selectionChange)="reloadRevenue()"
            >
                <mat-option class="left circle" [value]="Timespan.MONTH">
                    Months
                </mat-option>
                <mat-option class="left circle" [value]="Timespan.QUARTER">
                    Quarters
                </mat-option>
                <mat-option class="left circle" [value]="Timespan.YEAR">
                    Years
                </mat-option>
            </mat-select>
        </mat-form-field>

    </div>

    <canvasjs-chart *ngIf="revenueByTimespanData | async as revenueData" [options]="buildSingleChart(revenueData, revenueTitleInEuro, 'line', '€')" class="chart-style" fxFlex></canvasjs-chart>

    <div *ngIf="revenueByTimespanData | async as revenueData" fxLayout="column" fxFlex>
        <div class="cs-table-row enclosing">
            <div fxFlex="25"></div>
            <div fxFlex="25">Timespan</div>
            <div fxFlex="25">Amount</div>
            <div fxFlex="25">Contribution</div>
        </div>
        <div class="cs-table-row">
            <div fxFlex="25">Best</div>
            <div fxFlex="25">{{getTotalRevenueByTimespan(revenueData).label[0]}}</div>
            <div fxFlex="25">{{getTotalRevenueByTimespan(revenueData).data[1] + ' €'}}</div>
            <div fxFlex="25">{{((getTotalRevenueByTimespan(revenueData).data[1]/getTotalRevenueByTimespan(revenueData).data[0]) * 100).toFixed(2) + '%'}}</div>
        </div>
        <div class="cs-table-row">
            <div fxFlex="25">Worst</div>
            <div fxFlex="25">{{getTotalRevenueByTimespan(revenueData).label[1]}}</div>
            <div fxFlex="25">{{getTotalRevenueByTimespan(revenueData).data[2] + ' €'}}</div>
            <div fxFlex="25">{{((getTotalRevenueByTimespan(revenueData).data[2]/getTotalRevenueByTimespan(revenueData).data[0]) * 100).toFixed(2) + '%'}}</div>
        </div>
        <div class="cs-table-row">
            <div fxFlex="25">Current</div>
            <div fxFlex="25">{{getTotalRevenueByTimespan(revenueData).label[2]}}</div>
            <div fxFlex="25">{{getTotalRevenueByTimespan(revenueData).data[3] + ' €'}}</div>
            <div fxFlex="25">{{((getTotalRevenueByTimespan(revenueData).data[3]/getTotalRevenueByTimespan(revenueData).data[0]) * 100).toFixed(2) + '%'}}</div>
        </div>
        <div class="cs-table-row">
            <div fxFlex="25">Total</div>
            <div fxFlex="25">All</div>
            <div fxFlex="25">{{getTotalRevenueByTimespan(revenueData).data[0] + ' €'}}</div>
            <div fxFlex="25">100%</div>
        </div>
    </div>



    <canvasjs-chart *ngIf="revenueProjectData | async as revenueData" [options]="buildSingleChart(revenueData, revenueTitlePerProjectInEuro, 'bar', '€')" class="chart-style" fxFlex style="margin-top: 20px"></canvasjs-chart>


            <div *ngIf="revenueProjectData | async as revenueData" fxLayout="column" fxFlex>
                <div class="cs-table-row enclosing">
                    <div fxFlex="25"></div>
                    <div fxFlex="25">Project</div>
                    <div fxFlex="25">Amount</div>
                    <div fxFlex="25">Contribution</div>
                </div>
                <div class="cs-table-row">
                    <div fxFlex="25">Best</div>
                    <div fxFlex="25">{{getTotalRevenueByProject(revenueData).label[0]}}</div>
                    <div fxFlex="25">{{getTotalRevenueByProject(revenueData).data[1] + ' €'}}</div>
                    <div fxFlex="25">{{((getTotalRevenueByProject(revenueData).data[1]/getTotalRevenueByProject(revenueData).data[0]) * 100).toFixed(2) + '%'}}</div>
                </div>
                <div class="cs-table-row">
                    <div fxFlex="25">Worst</div>
                    <div fxFlex="25">{{getTotalRevenueByProject(revenueData).label[1]}}</div>
                    <div fxFlex="25">{{getTotalRevenueByProject(revenueData).data[2] + ' €'}}</div>
                    <div fxFlex="25">{{((getTotalRevenueByProject(revenueData).data[2]/getTotalRevenueByProject(revenueData).data[0]) * 100).toFixed(2) + '%'}}</div>
                </div>
                <div class="cs-table-row">
                    <div fxFlex="25">Total</div>
                    <div fxFlex="25">All</div>
                    <div fxFlex="25">{{getTotalRevenueByProject(revenueData).data[0] + ' €'}}</div>
                    <div fxFlex="25">100%</div>
                </div>
            </div>


</mat-card>

<mat-card appearance="outlined" *ngIf="visibility.includes(HomeCard.VACATION)">
    <h2>Vacation Overview</h2>

    <canvasjs-chart *ngIf="absenceVacationDaysLeftData | async as absenceData" [options]="buildMultipleChart(absenceData, 'Vacation Days left', 'bar', ['Vacation Days left', 'Total Number of Vacation Days'], 'Tage')" class="chart-style" fxFlex style="margin-top: 20px"></canvasjs-chart>

    <canvasjs-chart *ngIf="m.roles.includes('HR') && absenceThisWeekData | async as absenceData" [options]="buildMultipleChart(absenceData, 'Attendance this week (CW ' + getCurrentWeekNumber() + ')', 'line', ['First Half of Day', 'Second Half of Day'], '%')" class="chart-style" fxFlex></canvasjs-chart>

    <div fxLayout="column" fxLayoutAlign="start start" *ngIf="absenceData | async as absence" class="absence-box" style="margin-top: 20px">

        <h2>Today's Attendance</h2>

        <div fxLayout="row" fxLayoutGap="30px">
            <mat-form-field style="margin-left: 15px">
                <input
                    matInput
                    [(ngModel)]="absenceName"
                    id="absenceName"
                    name="absenceName"
                    placeholder="Search User"
                    maxlength="64"
                    type="text"
                    (ngModelChange)="changeAbsenceSelection(absence)"
                />
            </mat-form-field>

            <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
                <div
                    style="
                        width: 10px;
                        height: 10px;
                        display: inline-block;
                        background-color: rgba(102, 255, 0, 0.5);
                        box-shadow: rgba(102, 255, 0, 0.3) 2px 2px;
                    "
                ></div>
                <div>Presence</div>
            </div>

            <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
                <div
                    style="
                        width: 10px;
                        height: 10px;
                        display: inline-block;
                        background-color: rgba(255, 0, 0, 0.5);
                        box-shadow:  rgba(255, 0, 0, 0.3) 2px 2px;
                    "
                ></div>
                <div>Illness</div>
            </div>

            <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
                <div
                    style="
                        width: 10px;
                        height: 10px;
                        display: inline-block;
                        background-color: rgba(238,130,238, 0.5);
                        box-shadow: rgba(238,130,238, 0.3) 2px 2px;
                    "
                ></div>
                <div>Businesstrip</div>
            </div>

            <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
                <div
                    style="
                        width: 10px;
                        height: 10px;
                        display: inline-block;
                        background-color: rgba(255, 140, 0, 0.5);
                        box-shadow: rgba(255, 140, 0, 0.3) 2px 2px;
                    "
                ></div>
                <div>Vacation</div>
            </div>

            <div fxLayout="row" fxLayoutAlign="center center">
                <mat-checkbox
                    name="enabled-present"
                    id="enabled-present"
                    [(ngModel)]="showOnlyPresent"
                >
                    Show only present
                </mat-checkbox>
            </div>

        </div>

        <div *ngIf="!absenceName" class="absence-body" fxLayout="column" fxLayoutAlign="start start">
            <div fxLayout="row" *ngFor="let col of splitArray(absence, 4); let i = index">
                <div *ngFor="let name of col.label; let j = index" [ngClass]="getCssClasses(col.data[j])">
                    {{name}}
                </div>
            </div>
        </div>

        <div *ngIf="absenceName" class="absence-body" fxLayout="column" fxLayoutAlign="start start">
            <div fxLayout="row" *ngFor="let col of splitArray(selectedAbsence, 4); let i = index">
                <div *ngFor="let name of col.label; let j = index" [ngClass]="getCssClasses(col.data[j])">
                    {{name}}
                </div>
            </div>
        </div>

    </div>

    <button mat-stroked-button color="primary" (click)="openAbsences()" *ngIf="m.roles.includes('HR')">View Absences</button>
</mat-card>


</div>
